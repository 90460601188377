import React from 'react'
import { Layout, Warpper } from '@raylink-overseas/common/components'
import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import Bg404Src from '../images/404.png'
import { Link } from 'gatsby'
import useHref from '@raylink-overseas/common/hooks/use-href'

const NotFoundWrapper = styled(Warpper)`
  padding: 209px 0 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin: 10px 0 48px;
    color: #1f2329;
  }
  a {
    display: block;
    padding: 12px 16px;
    background: linear-gradient(90deg, #197ef3 0%, #445fff 100%);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  a:hover {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      linear-gradient(90deg, #197ef3 0%, #445fff 100%);
  }
  a:active {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.05) 100%
      ),
      linear-gradient(90deg, #197ef3 0%, #445fff 100%);
  }
  ${Media.phone`
    padding: 3.48rem 0 3.64rem;
    img {
      width: 5.56rem;
    }
    .title {
      font-size: 0.4rem;
      line-height: 0.72rem;
      margin: 0 0 0.64rem;
    }
    a {
      padding: 0.16rem 0.48rem;
      border-radius: 0.12rem;
      font-size: 0.28rem;
    }
  `}
`

const NotFoundPage = () => {
  const paramsHref = useHref()
  return (
    <Layout>
      <NotFoundWrapper>
        <img src={Bg404Src} alt="" />
        <div className="title">Page Not Found</div>
        <Link to={paramsHref('/')}>Return Homepage</Link>
      </NotFoundWrapper>
    </Layout>
  )
}

export default NotFoundPage
